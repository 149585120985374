.shadowInput {
  box-shadow: 5px 5px 8px -7px rgb(136, 129, 129);
}
.custom-placeholder-input::placeholder {
  position: absolute;
  top: 15px;
  left: 20px;
  transform: translateY(0);
  display: block;
}
.custom-placeholder-textarea {
  padding-bottom: 9rem;
  padding-left: 1.2rem;
}
.text-gradientTitle {
  background: linear-gradient(to right, #08235f, #0a4ad5);
  -webkit-background-clip: text;
  color: transparent;
}

.gradientButton {
  background: linear-gradient(to right, #082460, #0a41ba);
  color: white;
}

.w-125 {
  width: 200%;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.backgroundCont{
  background-image: url("../assets/backgroundContato.svg");
  background-size: cover;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
 }
 
.titlePlataforma {
    background: linear-gradient(to right, #08235f, #0a4ad5);
    -webkit-background-clip: text;
    color: transparent;

}
.backGroundSolucoes1{
    background-image: url("../assets/backgroundSolucoes1.svg");
    background-position: center;
    background-repeat: no-repeat;
   }
   @media (min-width: 768px) {
    .backGroundSolucoes1 {
      height: 64vh;
      background-position: center;
      background-size: cover;
      width: 100%;
    }
  }


.bg-Plat {
    background-image: url("../assets/bg-Plataforma.svg");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.h-00 {
    height: 1px;
}

.backgroundGrandePlat{
    background-image: url("../assets/backgroundSolucoes2.svg");
    background-size: cover;
    background-position: center;
  }
  @media (min-width: 768px) {
    .backGroundSolucoes1 {
      background-position: center;
    
      width: 100%;
      top: -100px;      
      background-size: cover;
    }
  }
  

.textPerso {
    font-size: 2.50rem;

    line-height: 2.90rem;
}
.bg-frota {
    background-image: url("../assets/bg-Spro.svg");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.opacity-0 {
  opacity: 0;
  transform: translateX(-100%);
}

.opacity-100 {
  opacity: 1;
  transform: translateX(0);
}

.transition-opacity {
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.backgroundSpro{
  background-image: url("../assets/backgroundSpro.svg");
  
  
  
  background-position: center;
 
 }
 @media (min-width: 768px) {
  .backgroundSpro {
    height: 60vh; 
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    
  }
}
 

.backgroundGrandeSpro{
  background-image: url("../assets/backgroundSproGrande.svg");
  background-size: cover;
  
}

@media (min-width: 768px) {
  .backgroundGrandeSpro {
    height: 130vh;
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    
  }
}

@keyframes moveCar {
    0% {
      transform: translateX(-80%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .car-animation {
    animation: moveCar 2s ease-in-out forwards;
  }
  .backgroundGrandeMent{
    background-image: url("../assets/backgroundSproGrande.svg");
    background-size: cover;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
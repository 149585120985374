@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");


* {
  font-family: Montserrat;
}
.backGroundHome1 {
  background-image: url("../assets/BackGroundHome1.svg");
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .backGroundHome1 {
    height: 64vh; 
    width: 100%;
    background-position: center;
    background-size: cover;

    
  }
}

 
.text-gradient {
  background: linear-gradient(to right, #08235f, #0a4ad5);
  -webkit-background-clip: text;
  color: transparent;
}

.text-gradientCentral {
  background: linear-gradient(to right, #313179, #09338f);
  -webkit-background-clip: text;
  color: transparent;
}

.text-gradientButton {
  background: linear-gradient(to right, #082460, #0a41ba);
  color: white;
}



.shadowFunctions {
  box-shadow: 0px 0px 8px 0px rgb(136, 129, 129);
}

.macBook {
  top: 370px;

  left: 65%;
}

.borderRight {
  border-right: #0a389b solid 2px;
}

.gradientBg {
  background-image: url("../assets/BackgroundGradient.svg");
  background-size: cover;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;

}

.gradientBgTec {
  background-image: url("../assets/BackgroundGradient.svg");
  background-size: cover;
  background-position: center;
  background-size: cover;
}

.backgroundNossoApp{
  background-image: url("../assets/backGroundHome2.svg");
  background-size: cover;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.buttonAppPersonalizado {
  background: linear-gradient(to right, #082460, #0a41ba);
  color: white;
}

.h-vh90 {
  height: 90vh;
}

.text-QuemSomos {
  background: linear-gradient(to right, #08235f, #0a4ad5);
  -webkit-background-clip: text;
  color: transparent;
}

.text-gradientSelsyn {
  background: linear-gradient(to right, #08235f, #0a4ad5);
  -webkit-background-clip: text;
  color: transparent;
}

.bordaAzul {
  background: linear-gradient(to right, #08235f, #0a4ad5);
}

.text-Azul {
  background: #092c77;
  -webkit-background-clip: text;
  color: transparent;
}

.bgClaro {
  background-color: #bdd2ff;
}

.bgProp {
  background-color: #092563;
}

.bgMiss {
  background-color: #093490;
}

.bgVis {
  background-color: #0a3eb0;
}

.bgVal {
  background-color: #0b4ad4;
}

.borderDiv {
  border-radius: 3rem 0rem 0 3rem;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  position: absolute;
  top: 50%;
  right: -100%;
  transform: translateY(-50%);
  transition: right 1s ease-out;
}

.container-Ide {
  position: relative;
  overflow: hidden;
}

.content.visible {
  right: 0;
}

.section {
  position: relative;
  min-height: 100vh;
  padding: 50px 0;
}

.borderDiv {
  position: relative;
  transform: translateX(100%);
  transition: transform 2.5s ease;
}

.container-Proprositos.visible {
  transform: translateX(0);
}

.bg-Footer {
  background-image: url("../assets/bg-footer.svg");
  width: 100%;
  background-position: center;
  background-size: cover;


}

.backgroundGrande{
  background-image: url("../assets/BACKGROUNDSGrande.svg");
  background-size: cover;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (width >=1550px) {
  .gradientBg {
    background-image: url("../assets/BackgroundGradient.svg");
    background-position: center;
    background-size: cover;
    height: 80vh;
  }
}
.backgroundMigracao{
    background-image: url("../assets/backgroundSdriver.svg");
    background-size: cover;
    width: 100%;
    background-position: center;
  }

  .bgMigracaoEtap {
    background-image: url("../assets/bg-Spro.svg");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


.fontBebasNeue{
    font-family: 'Bebas Neue', cursive;
}

img{
    pointer-events: none;
}